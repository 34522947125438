import React, {useCallback, useContext, useEffect, useState} from 'react';
import './ChainsDropDown.scss';
import {
	ChainIcon,
	DeletingIcon,
	EditIcon, EllipsisIcon,
	PlusIcon,
} from "../../../../image";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {addChain, deleteChain, getChains, updateChain} from "../../../../redux/actions/chainsActions";
import {AddVideoToChain, DeleteVideoToChain, GetChannelById, GetChannels} from "../../../../redux/actions";
import {Form, Toast} from "react-bootstrap";
import {Link} from "react-router-dom";
import {VIDEOS_CONSTS} from "../../../../redux/constants";
import {hasPermission, hexToRgbA} from "../../../../utils/helperFunctions";
import {Popover} from "antd";
import {ROUTERS_MAP} from "../../../../utils/routes";
import {ToastContext} from "../../../../modules/Toast/ToastContext";
import {ROLE_ITEMS} from "../../../../utils/constants";


const ChainsDropDown = (props) => {
	const {chains, channel, isBindChain = false, videoInfo, isAll = false, channelStyle, isVideoChain} = props;
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [chainName, setChainName] = useState('');
	const [isVisibleAddForm, setIsVisibleAddForm] = useState(false);
	const toggleIsVisibleAddForm = useCallback(() => setIsVisibleAddForm(prevState => !prevState), []);
	const [editChainInfo, setEditChainInfo] = useState('');
	const videoChains = videoInfo?.chains_ids || [];
	const {setToastInfo} = useContext(ToastContext);
	const closeAndClearInput = () => {
		toggleIsVisibleAddForm();
		setChainName('');
		setEditChainInfo({});
	}
	console.log('channel',channel)
	const onHandleClickChangeChain = (id) => {
		const findChain = chains.find((item) => item?.id === id);
		setEditChainInfo(findChain || {})
		toggleIsVisibleAddForm();
		setChainName(findChain.name);
	}

	const AddChain = async () => {
		try {
			await dispatch(addChain({
				name: chainName,
				channel_id: channel.id,
				description: ""
			}));
			if(!isAll) {
				await dispatch(GetChannelById(channel?.id));
			} else {
				await dispatch(GetChannels());
				await dispatch(getChains());
			}
			closeAndClearInput();
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("add_chain_success_text")
			});
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("add_chain_error_text")
			});
			console.log(e)
		}
	}

	const DeleteChain = async (id) => {
		const findChain = chains.find((item) => item?.id === id);
		try {
			await dispatch(deleteChain(id, {
				name: findChain?.name,
				channel_id: channel.id,
				description: findChain?.description
			}));
			if(!isAll) {
				await dispatch(GetChannelById(channel?.id));
			} else {
				await dispatch(GetChannels());
			}
			await dispatch(getChains());

			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("delete_chain_success_text")
			});
			if(isBindChain) {
				dispatch({
					type: VIDEOS_CONSTS.DELETE_CHAIN_TO_VIDEO,
					payload: {video_id: videoInfo?.id, data: [{is_bound: false, id}]}
				})
			}
		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("delete_chain_error_text")
			});
			console.log(e)
		}
	}

	const UpdateChain = async () => {
		try {
			await dispatch(updateChain(editChainInfo?.id, {
				name: chainName,
				channel_id: channel.id,
				description: editChainInfo?.description
			}));
			if(!isAll) {
				await dispatch(GetChannelById(channel?.id));
			} else {
				await dispatch(GetChannels());
				await dispatch(getChains());
			}
			closeAndClearInput();
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("update_chain_success_text")
			});

		} catch (e) {
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("update_chain_error_text")
			});
			console.log(e)
		}
	}

	const onCheckBoxClick = async (e, id) => {
		try {
			if(e?.target?.checked) {
				await dispatch(AddVideoToChain(videoInfo?.id, [{
					id: Number(id),
					is_bound: true
				}]));
				setToastInfo({
					isShow: true,
					type: 'success',
					text: t('video_link_to_chain_error')
				});
			} else {
				await dispatch(DeleteVideoToChain(videoInfo?.id, [{
					id: Number(id),
					is_bound: false
				}]));
				setToastInfo({
					isShow: true,
					type: 'success',
					text: t('video_detach_to_chain_success')
				});
			}
			if(!isAll) {
				await dispatch(GetChannelById(channel?.id));
			} else {
				await dispatch(GetChannels());
				await dispatch(getChains());
			}
		} catch (e) {
			console.warn(e)
		}
	}
	const isValidateCheckBox = (item) => {
		const chainIds = (chains || []).map((item)=> item?.id);
		const sortId = videoChains?.filter((item) => chainIds.includes(item));
		return isVideoChain ? !sortId.includes(item?.id) && sortId.length >= 3 : false;
	}

	useEffect(()=>{
		return () => {
			setToastInfo({
				isShow: false,
				type: 'danger',
				text: null
			});
		}
	},[])

	return (
		<>
			<div
				onClick={(e)=>e.stopPropagation()}
				className="chains-dropdown" style={{
				'--chain_сolor': channelStyle?.color  || '#33BCC5',
				'--chain_bg': channelStyle?.bgColor || hexToRgbA( '#33BCC5', 0.1)
			}}>
				<div className="chains-dropdown__header">
					<p className="chains-dropdown__header--title">{t('chains')}</p>
				</div>
				<div className="chains-dropdown__wrap custom-scroll">
					{(!isVisibleAddForm && (hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || channel?.is_private ))  ?
						<div className="chains-dropdown__add" onClick={toggleIsVisibleAddForm}>
							<PlusIcon/>
							<span className="chains-dropdown__add--title">{t('add_chain')}</span>
						</div>
						: null}
					{isVisibleAddForm && !editChainInfo?.id ?
						<div className="input-wrap">
							<input
								onChange={(e) => {
									const value = e?.target?.value;
									e.stopPropagation();
									if(value.length <= 20) {
										setChainName(e.target.value);
									}
								}}
								value={chainName}
								name="chain-name"
								className="input-wrap__input"
								placeholder={t('input_placeholder_chain')}
								type="text"
							/>
							<div className="input-wrap__buttons">
								<button
									onClick={closeAndClearInput}
									className={`close_btn`}
								>
									{t('button_cancel')}
								</button>
								<button
									className={`save_btn ${!chainName ? 'disabled' : ''}`}
									disabled={!chainName}
									onClick={AddChain}
								>
									{t('button_save')}
								</button>
							</div>
						</div>
						: null}
					{chains.map((item) => (
						<>
							{item?.id === editChainInfo?.id ?
								<div className="input-wrap" key={item.id}>
									<input
										onChange={(e) => {
											e.stopPropagation();
											setChainName(e.target.value);
										}}
										value={chainName}
										name="chain-name"
										className="input-wrap__input"
										placeholder={t('input_placeholder_chain')}
										type="text"
									/>
									<div className="input-wrap__buttons">
										<button
											onClick={closeAndClearInput}
											className={`close_btn`}
										>
											{t('button_cancel')}
										</button>
										<button
											className={`save_btn ${!chainName ? 'disabled' : ''}`}
											disabled={!chainName}
											onClick={UpdateChain}
										>
											{t('button_save')}
										</button>
									</div>
								</div>
								:
								<div className={'chain-item'} key={item?.id}>
									{(isBindChain && (hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || channel?.is_private )) ?
										<Form.Check
											type={'checkbox'}
											checked={videoChains.includes(item?.id)}
											disabled={isValidateCheckBox(item)}
											onChange={(e) => onCheckBoxClick(e, item?.id)}
										/>
										: null}
									<Link
										to={{
											pathname: `${ROUTERS_MAP.CHANNEL}/${channel?.id}/chains/${item?.id}`,
											state: {
												routs: [
													{
														path: ROUTERS_MAP.KNOWLEDGE_DATA,
														name: t('menu_item_knowledge')
													}
												]
											}
										}}
										className="chain-item--name"
									>

										<div className="chain-item__badge">
											<ChainIcon/>
											<span className="chain-item__badge--text">{item?.name}</span>
										</div>
									</Link>
									{(hasPermission(ROLE_ITEMS.FOREIGN_VIDEO_CHAINS_FULL_ACCESS) || channel?.is_private ) ?
									<Popover
										arrow={false}
										trigger='click'
										placement="rightTop"
										overlayClassName='popover_edit-menu'
										content={
											<>
												<div
													className={'popover_edit-menu--item'}
													onClick={(e) => {
														e.stopPropagation();
														onHandleClickChangeChain(item?.id)
													}}
												>
													<EditIcon className={''}/> {t('button_edit')}
												</div>
												<div
													className={'popover_edit-menu--item delete'}
													onClick={(e) => {
														e.stopPropagation();
														DeleteChain(item?.id)
													}}
												>
													<DeletingIcon className={''}/> {t('button_delete')}
												</div>
											</>
										}
									>
										<div className="popover_edit-menu--settings">
											<EllipsisIcon/>
										</div>
									</Popover>
								: null}
								</div>
							}
						</>

					))}
				</div>
			</div>
		</>
	);
};

export default ChainsDropDown;