import React from "react";
import {store} from "../redux/store";
import dayjs from "dayjs";
import {monthsShort, monthsShortEn, ROLE_ITEMS} from "./constants";

export function getTimeString(start_time) {
    let sec_num = parseInt(start_time) || 0;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}

export function parseTime(time) {
    const timeArr = time.split(':')
    let sec = parseInt(timeArr[timeArr.length - 1]) || 0;
    let min = parseInt(timeArr[timeArr.length - 2]) || 0;
    let hour = parseInt(timeArr.length >= 3 ? timeArr[0] : 0) || 0
    return sec + (min * 60) + (hour * 3600)
}

export const timeWithDots = (time) => {
    let arr = time.split('')
    let newArr = []

    for (let i = 0; i < arr.length; i++) {
        if ((i + 1) % 3 === 0) {
            newArr.push(':')
        } else {
            newArr.push(arr[i])
        }
    }

    return newArr.join('')
}

export const changeTimeFormat = (date, language = 'ru') => {
    const day = dayjs(date).date();
    const monthIndex = dayjs(date).month();
    const year = dayjs(date).year();
    const month = language === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

    return `${day} ${month} ${year}, ${dayjs(date).format('HH:mm:ss')}`;
}

export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getAuthorName(data, id, lng) {
    return data?.first_name ? `${data.first_name ? data.first_name : ''} ${data?.last_name ? data?.last_name : ''}`
        : `id-${id || data?.id || ''} ${lng === 'ru' ? 'неизвестный' : 'unknown'}`
}

export function getLocalTime(date) {
    const utcDate = new Date(date);
    const userLocale = navigator.language || 'en-US';

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return new Intl.DateTimeFormat(userLocale, options).format(utcDate);
}

export function hasPermission(key) {
    const permissions = store.getState().profile?.userRoles?.permissions;
    // console.log('permissions',permissions)
    return !!permissions?.find(i => i.name === key)
}



export const formatDate = (date,lng) => {
    const day = dayjs(date).date();
    const monthIndex = dayjs(date).month();
    const year = dayjs(date).year();
    const month = lng === 'ru' ? monthsShort[monthIndex] : monthsShortEn[monthIndex];

    return `${day} ${month} ${year}`;
};

export const hexToRgbA = (hex, opacity) => {
    try {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    } catch (error) {
        return '';
    }
};

export function copyToClipboard(text) {
    if (!navigator.clipboard) {
        // Если API не поддерживается, fallback для старых браузеров
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';  // чтобы текстовое поле не было видимо на странице
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        try {
            document.execCommand('copy');
            console.log('Текст скопирован в буфер обмена.');
        } catch (err) {
            console.error('Ошибка при копировании текста в буфер обмена.', err);
        }

        document.body.removeChild(textarea);
        return;
    }

    navigator.clipboard.writeText(text).then(() => {
        console.log('Текст скопирован в буфер обмена.');
    }).catch(err => {
        console.error('Ошибка при копировании текста в буфер обмена.', err);
    });
}

export function findFirstIntersectionChannel(array1, array2) {
    for(let item1 of array1) {
        const matchingItem = array2.find(item2 => item1 === item2.id);
        if(matchingItem) {
            return matchingItem
        }
    }
    return null;
}

export const isCheckDetachPermission = (data,userProfile) => {
    console.log('data',data)
    console.log('userProfile',userProfile)
    return userProfile?.attached_person?.id === (data?.id || data?.person_id)
      ? hasPermission(ROLE_ITEMS.SELF_PROFILE_PARTICIPATION_EDIT)
      : (data?.status === 'new' || ((!data?.image_path || data?.image_path === "None") && !data?.first_name) || !data?.id )
        ? hasPermission(ROLE_ITEMS.FOREIGN_PARTICIPANTS_PROFILE_PARTICIPATION_EDIT)
        : hasPermission(ROLE_ITEMS.FOREIGN_USERS_PROFILE_PARTICIPATION_EDIT);
}