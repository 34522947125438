// Import packages
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

// Import assets
import "react-datepicker/dist/react-datepicker.css";
import './event-item.scss';
import {
    AlarmIcon, CalendarVideoIcon,
    GoogleMeetIcon,
    GuestIcon, MicrosoftTeamsIcon, OutlookCalendarIcon, RecordGreenIcon,
    ShareGreenIcon, SuccessIcon, VideoGreyIcon
} from "../../../../image";


//Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {UpdateCalendarsEvents} from "../../../../redux/actions";
import {getLocalTime} from "../../../../utils/helperFunctions";
import {useTranslation} from "react-i18next";
import {Toast} from "react-bootstrap";
import {history} from "../../../../utils/history";
import {Tooltip} from "antd";

function EventItem(props) {
    const {data} = props;
    const [enabled, setEnabled] = useState(true);
    const [showToast, setShowToast] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        setEnabled(data?.need_record)
    }, [data?.need_record])

    function copyPersonLink(url) {
        navigator.clipboard.writeText(url);
        toggleToast()
    }

    function toggleNeedRecord() {
        setEnabled(!enabled)
        props.UpdateCalendarsEvents(data?.id, {
            "need_record": !enabled
        })
    }

    function navigateTo() {
        data?.is_actual ? window.open(data?.html_link)
            : history.push({
                pathname: `/dashboard/videos/video/${data?.video_id}`,
                state: {
                    routs: [
                        {
                            name: t('calendar_title')
                        },
                    ]
                }

            })
    }

    const toggleToast = () => setShowToast(!showToast);

    function ErrorToast() {
        return <Toast show={showToast}  autohide={true} onClose={toggleToast} className={'upload_video_answer'}>
            <div className="toasblock">
                 <SuccessIcon/>
                <div className="toasblock__wrap">
                    <span className="toasblock__wrap--title">{t('toast_success_title')}</span>
                    <span className="toasblock__wrap--text">{t('toast_success_description')}</span>
                </div>
            </div>
        </Toast>
    }

    const dateStart = getLocalTime(data?.datetime_start)
    const dateEnd = getLocalTime(data?.datetime_end)


    return <>
        <div className={`calendar__event ${data?.is_actual ? '' : 'passed'}`}>
            <div className="calendar__event__title">
                {(data?.summary || '').length >19 ?
                  <Tooltip placement="top" title={data?.summary}>
                      <span>
                           {data?.summary}
                      </span>
                  </Tooltip>
                  : <span>
                        {data?.summary}
                    </span>
                }
            </div>
            <div className="calendar__event__row">
                <AlarmIcon/>
                {dateStart} - {dateEnd}
            </div>
            <div className="calendar__event__row">
                <GuestIcon/>
                {data?.attendees?.length} {t('calendar_guest')}
            </div>
            <div className="calendar__event__row">
                <div onClick={navigateTo} className="social see-video">
                    {!data?.is_actual ? <VideoGreyIcon/> :
                        data?.user_calendar?.calendar_service?.name === 'google'
                          ? <GoogleMeetIcon/>
                            : data?.user_calendar?.calendar_service?.name === 'outlook'
                                ? <MicrosoftTeamsIcon/>
                                : <CalendarVideoIcon/>
                    }
                    {data?.is_actual ? t('calendar_join_meeting') : t('see')}
                </div>
                {data?.is_actual ?
                <div className="social">
                    <ShareGreenIcon className="social-icon"
                                    onClick={() => data?.is_actual && copyPersonLink(data?.html_link)}/>
                    <div className={`custom_switch ${enabled ? 'enabled' : ''}`}
                         onClick={data?.is_actual && toggleNeedRecord}>
                        <RecordGreenIcon/>
                    </div>
                </div> : null}
                {!['outlook', 'google'].includes(data?.user_calendar?.calendar_service?.name) ?
                  <div className="calendar__event--vks">
                      {t('no_detected_vks')}
                  </div>
                  : null
                }
            </div>
        </div>

        {ErrorToast()}
    </>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    UpdateCalendarsEvents
};

export default connect(mapStateToProps, mapDispatchToProps)(EventItem);
