import {USER_CALENDAR_CONSTS} from "../constants";
import {_urlUserCalendars, request} from "../api";


export const GetUserCalendars = () => {
    let requestData = {
        url: `${_urlUserCalendars}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GET_USER_CALENDARS,
                    payload: data
                })
            })
    };
};
export const GetCalendarsEvents = ({datetime_start, datetime_end}) => {
    let requestData = {
        url: `${_urlUserCalendars}/calendar_events`,
        method: "GET"
    };

    datetime_start && (requestData.url = requestData?.url + `?datetime_start=${datetime_start}`)
    datetime_end && (requestData.url = requestData?.url + `&datetime_end=${datetime_end}`)

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GET_CALENDAR_EVENTS,
                    payload: data
                })
            })
    };
};
export const UpdateUserCalendars = (id, data) => {
    const requestData = {
        url: `${_urlUserCalendars}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: USER_CALENDAR_CONSTS.UPDATE_USER_CALENDAR,
                    payload: {id, data}
                });
            })

    }
};

export const UpdateCalendarsEvents = (id, data) => {
    const requestData = {
        url: `${_urlUserCalendars}/calendar_events/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return () => {
        return request(requestData)
    }
};

export const GetCalendarSynchronize = () => {
    let requestData = {
        url: `${_urlUserCalendars}/synchronize`,
        method: "GET",
    };

    return () => {
        return request(requestData)
            .then(async (res) => {
                // const data = res && res.data;
                return res?.data
                // await dispatch({
                //     type: USER_CALENDAR_CONSTS.GET_CALENDAR_SYNCHRONIZE,
                //     payload: data
                // })
            })
    };
};

export const GoogleAuth = (data) => {
    let requestData = {
        url: `${_urlUserCalendars}/google_auth_data`,
        method: "POST",
        data,
    };
    console.log('GoogleToken data', data);
    // data && (requestData.url = requestData?.url + `?code=${data}`)
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GOOGLE_AUTH,
                    payload: res?.data
                })
            })
    };
};


export const CalendarLogOut = () => {
    let requestData = {
        url: `${_urlUserCalendars}/google_auth_data`,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(async () => {
            })
    };
};
export const GetGoogleAuth = () => {
    let requestData = {
        url: `${_urlUserCalendars}/google_auth_data`,
        method: "GET",
    };

    return (dispatch) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: USER_CALENDAR_CONSTS.GET_GOOGLE_AUTH,
                    payload: data
                })
            })
    };
};

export const GetAuthorizationUrlOutlook = () => {
    let requestData = {
        url: `${_urlUserCalendars}/outlook_auth_process_stepone`,
        method: "POST",
    };
    return request(requestData)
        .then(async (res) => {
            return res?.data
        })
};

export const GetOutlookAuth = (data) => {
    let requestData = {
        url: `${_urlUserCalendars}/outlook_auth_data`,
        method: "GET",
    };
    // data && (requestData.url = requestData?.url + `?code=${data}`)
    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: USER_CALENDAR_CONSTS.OUTLOOK_AUTH,
                    payload: res?.data
                })
            })
    };
};

export const DeleteOutlookAuth = (data) => {
    let requestData = {
        url: `${_urlUserCalendars}/outlook_auth_data`,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(async () => {
                await dispatch({
                    type: USER_CALENDAR_CONSTS.OUTLOOK_LOG_OUT,
                })
            })
    };
};