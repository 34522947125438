import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import './CheckBox.scss'
import { v4 as uuidv4 } from 'uuid';

const CheckBox = (props) => {
	const {checked, onChange, children,label, type="checkbox"} = props;
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(checked)
	}, [checked]);

	return (
		<Form.Check
			id={uuidv4()}
			type={type}
			checked={isChecked}
			onChange={onChange}
			className="custom-checkbox"
			label={label}
		/>
	);
};

export default CheckBox;