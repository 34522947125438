// Import packages
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import {
	DeletingIcon, EditIcon,
	EditingIcon,
	EllipsisIcon,
	RefreshIcon, ShevronTopIcon
} from "../../../../image";

// Import Components
import DropdownMenu from "../../../../shared/features/dropdownMenu";
import UpdateVideoModal from "../../../../shared/modals/updateVideolModal/UpdateVideoModal";
import LinkDropdown from "../LinkDropdown/LinkDropdown";
import ChannelsUpdatingList from "../../../../shared/features/ChannelsUpdatingList/ChannelsUpdatingList";

// Import Utils
import {history} from "../../../../utils/history";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
	AddChannel,
	AddVideoDescription,
	DeleteChannel,
	DeleteVideo, ReprocessingVideoService, ReprocessVideo, UpdateChannel,
	UpdateVideoChannel
} from "../../../../redux/actions";
import {ROLE_ITEMS, statusesList, statusesListRu} from "../../../../utils/constants";
import {useTranslation} from "react-i18next";
import ChannelsModal from "../../../../shared/modals/channelModal/ChannelsModal";
import UserInfoModal from "../../../Dashboard/containers/Users/modals/userInfoModal/UserInfoModal";
import AvatarGroup from "../AvatarGroup/AvatarGroup";
import {Popover} from "antd";
import {Loader} from "../../../../shared/features/Loader";
import InfoTooltip from "../../../Dashboard/containers/Profile/tooltip";
import {ROUTERS_MAP} from "../../../../utils/routes";
import {hasPermission} from "../../../../utils/helperFunctions";


function ListItem(props) {
	const {data, channelsList, userRoles, userProfile, activePage, reprocessingVideo, isLoading} = props;

	const [isRename, setIsRename] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [channelsMenuIsClosed, setChannelsMenuIsClosed] = useState(true);
	const [editingChannel, setEditingChannel] = useState('');
	const [openCreate, setOpenCreate] = useState(false);
	const [showChannelModal, setShowChannelModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const {t, i18n} = useTranslation();
	const [open, setOpen] = useState(false);

	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443'];
	const userProfileInfo = useSelector(state => state.profile?.userProfile)

	const sortUsers = (data?.owners || []).sort((item) => {
		if(userProfileInfo?.id === item?.user_id || userProfileInfo?.attached_person?.id === item?.person_id) {
			return -1
		}
		return 1
	})

	const isOwner = ((data?.owners || []).some((item) => item?.user_id === userProfile?.id)
		|| (data?.owners || []).some((item) => item?.person_id === userProfile?.attached_person?.id));

	const checkPermissionDeleteVideo = isOwner ? hasPermission(ROLE_ITEMS.SELF_VIDEOS_DELETE)
		: hasPermission(ROLE_ITEMS.FOREIGN_VIDEOS_FULL_ACCESS)


	function toggleUserModal(person) {
		if(person && (userProfile?.attached_person?.id === (person.id || person?.user_id) || userProfile?.id === (person.id || person?.user_id))){
			history.push(ROUTERS_MAP.PROFILE);
		}else{
			setShowModal(!showModal)
			setCurrentUser(person)
		}
	}

	const navigateVideo = () => {
		!isRename && history.push({
			pathname: `/dashboard/videos/video/${data?.id}`,
			state: {
				routs: [
					{
						path: ROUTERS_MAP.MANAGE_DATA,
						name: t('manage_video_title')
					},
				]
			}
		})
	};
	const closePopovers = () => {
		setOpen(false);
	}

	function toggleReprocess(e) {
		closePopovers();
		e.stopPropagation()
		props.ReprocessVideo(data?.id)
	}

	function toggleEdit(e) {
		e.stopPropagation()
		setIsRename(true)
		setSelectedVideo(data)
	}

	function deleteVideo(e) {
		e.stopPropagation()
		props.DeleteVideo(data.id)
	}

	function handleCloseChannelsMenu() {
		setChannelsMenuIsClosed(!channelsMenuIsClosed)
	}


	function closeChannelsModal() {
		setShowChannelModal(false)
		setEditingChannel('')
		// handleHideModal(false)
		setOpenCreate(false)
	}

	const handleOpenChange = (open) => {
		setOpen(open);
	};
	const tableInfo = [
		{
			name: <span
				className={'clickable'}
				onClick={navigateVideo}
			>
                {(data.description?.length > 50
	                ? <InfoTooltip elem={data.description}
	                               title={data.description}/>
	                : data.description) || '-'}</span>,
			width: '12.8%',
			className: 'text'
		},
		{
			name: data.created_at,
			width: '7.7%',
			className: 'light'
		},
		{
			name: data?.duration ? data?.duration : '-',
			width: '8.2%',
			className: 'light'
		},
		{
			name: data?.video_status ? <InfoTooltip
				elem={i18n.language === 'ru' ? statusesListRu[data?.video_status]?.name : statusesList[data?.video_status]?.name}
				title={i18n.language === 'ru' ? statusesListRu[data?.video_status]?.key : statusesList[data?.video_status]?.key}/> : '-',
			width: '6.2%',
			className: 'light'
		},
		{
			name: <AvatarGroup
				users={sortUsers}
				isActiveUser={true}
				toggleUserModal={toggleUserModal}
			/>,
			width: '12.8%',
		},
		{
			name: <div className={'clickable'}>
				<LinkDropdown link={data?.link}
							  data={data}
				              video_id={data?.id}
				              isOwner={(data?.owners || []).some((item) => item?.user_id === userProfile?.id)}
				/>
			</div>,
			width: '10.9%',
			className: 'light'
		},
		{
			name: data?.channels?.length > 0 && <DropdownMenu
				button={<div key={data.date} className="channels-btn">
					{
						data?.channels?.length ? <span className="channels">
                        <div className="channel-item"
                             style={{
	                             backgroundColor: data?.is_private ? 'rgba(26, 187, 120, 0.10)'
		                             : tagColors[0] + '14',
	                             color: data?.is_private ? "#1ABB78" : tagColors[0],
	                             border: data?.is_private ? '0.6px solid #1ABB78' : '0'
                             }}>
                                    {channelsList[data?.channels[0]]?.name}
                                </div>
								{data?.channels.length > 1 &&
									<div className="channels-count">+{data?.channels.length - 1}</div>}
                                            </span>
							: <div className="no-channels">
								{t('no_channels')}
							</div>
					}
				</div>}
				placement={'bottom'}
				closeDropdown={channelsMenuIsClosed}
				onClose={handleCloseChannelsMenu}
				className={'link-dropdown'}
				closeAfterToggle={false}
				contentWidth={'347x'}
				fullWidth={false}>
				<ChannelsUpdatingList video={data}
				                      onHide={handleCloseChannelsMenu}
				                      setShowChannelModal={setShowChannelModal}
				                      setEditingChannel={setEditingChannel}
				                      setOpenCreate={setOpenCreate}
				                      className={'simple'}/>
			</DropdownMenu>,
			width: '15%',
		},
		{
			name: <AvatarGroup
				users={data.persons || []}
				videoId={data.id}
				activePage={activePage}
				isDetach={true}
				toggleUserModal={toggleUserModal}
			/>,
			width: '8.1%'
		},
	]

	return <div className={'table-row'} key={data.id}>
		{
			tableInfo?.map((item, index) => {
				return <div key={index}
				            className={item?.className}
				            style={{width: item?.width}}
				>{item?.name}</div>
			})
		}

		<div className={'actions-wrapper'}
		     style={{width: '2.2%'}}
		     onClick={(e) => e.stopPropagation()}>
			{open ?
				<Popover
					arrow={false}
					trigger='click'
					placement="leftTop"
					overlayClassName='popover_edit-menu'
					open={open}
					onOpenChange={handleOpenChange}
					content={
						<>
								<Popover
									arrow={false}
									trigger='click'
									placement="leftTop"
									overlayClassName='popover_edit-video'

									content={
										<>
											<div
												className="popover_edit-video--item"
												onClick={toggleReprocess}
											>
												{t('all_users_title')}
											</div>
											<Popover
												arrow={false}
												trigger='click'
												placement="left"
												overlayClassName='popover_edit-video'
												content={
													<>
														<div
															className="popover_edit-video--item"
															onClick={() => {
																closePopovers();
																reprocessingVideo(data?.id, undefined);
															}}
														>
															{t('default')}
														</div>
														<div
															className="popover_edit-video--item"
															onClick={() => {
																closePopovers();
																reprocessingVideo(data?.id, 'English');
															}}
														>
															{t('english')}
														</div>
														<div
															className="popover_edit-video--item"
															onClick={() => {
																closePopovers();
																reprocessingVideo(data?.id, 'Russian');
															}}
														>
															{t('russian')}
														</div>
													</>
												}
											>
												<div className="popover_edit-video--item popover_edit-video--item-wrap">
													{t('AI_notes_title')}
													<ShevronTopIcon className="popover_edit-video--item-arrow"/>
												</div>
											</Popover>
										</>
									}
								>
									<div
										className="popover_edit-video--item popover_edit-video--item-wrap"
									>
										<RefreshIcon/> {t('button_refresh')} <ShevronTopIcon className="popover_edit-video--item-arrow"/>
									</div>
								</Popover>

								<div
									className="popover_edit-video--item"
									onClick={toggleEdit}
								>
									<EditIcon/> {t('button_edit')}
								</div>
								{checkPermissionDeleteVideo && (
								 <div className="popover_edit-video--item" onClick={deleteVideo}>
									<DeletingIcon/> {t('button_delete')}
								</div>
								)}
						</>
					}
				>

				</Popover>
				: null}
			<div className="popover_edit-menu--settings">
				{(isLoading.show && isLoading?.id === data?.id) ? <Loader size={30}/> :
					<EllipsisIcon onClick={() => setOpen(true)}/>}
			</div>
		</div>
		{isRename && <UpdateVideoModal showModal={isRename}
		                               onHide={() => setIsRename(false)}
		                               video={selectedVideo}/>}

		{showChannelModal && <ChannelsModal showModal={showChannelModal}
		                                    channelById={!openCreate && editingChannel}
		                                    createChannel={openCreate}
		                                    onHide={closeChannelsModal}/>}
		{showModal && <UserInfoModal show={showModal} closeModal={toggleUserModal} user={currentUser}/>}

	</div>
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, ['channels', 'userProfile', 'refresh', 'userRoles'])
};

const mapDispatchToProps = {
	UpdateVideoChannel, AddVideoDescription, DeleteVideo, AddChannel, UpdateChannel, DeleteChannel, ReprocessVideo
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
