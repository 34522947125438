// Import packages
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";

// Import assets
import './profile.scss';
import 'react-phone-number-input/style.css'
import {
	ArrowDownIcon, DeletingIcon, ErrorIcon,
	EyeIcon,
	InfoCheckIcon, InfoIcon, SuccessIcon,
} from "../../../../image";

// Import Components
import {Tabs, Tab, Accordion, Form, Toast} from "react-bootstrap";
import DeletePerson from "../../../../shared/features/deletePerson";
import UserRoles from "../../../../shared/profile/UserRoles";
import ParticipationData from "./ParticipationData";
import InfoTooltip from "./tooltip";

// Import Utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
	UpdatePersons,
	ChangeHeaderTitle,
	ClearProfile,
	UpdateUser,
	GetUserProfile,
	GetUserById,
	GetVideos,
	GetHighlights,
	GetPersonById,
	GetUsersPersons,
	ClearPersonById,
	ClearVideoList,
	GetVideosService,
	GetChannels,
	DeleteUserService,
	LogOut, DeletePersonService, GetRoles
} from "../../../../redux/actions";
import {useTranslation} from "react-i18next";
import config from "../../../../utils/config"
import {validateData} from "./helpers/helpers";
import {ProfileContacts} from "../../../../modules/UserInfo/components/ProfileContacts";
import {ProfileMedia} from "../../../../modules/UserInfo/components/ProfileMedia";
import {ProfileTags} from "../../../../modules/UserInfo/components/ProfileTags";
import ProfileLanguage from "../../../../modules/UserInfo/components/ProfileLanguage/ProfileLanguage";
import {UserPersonInfoContext} from "../../../../modules/UserInfo/components/contexts";
import {isCheckContactInfo, isCheckUserInfo} from "../Users/modals/userInfoModal/helper";
import {history} from "../../../../utils/history";
import UserInfoModal from "../Users/modals/userInfoModal/UserInfoModal";
import {hasPermission} from "../../../../utils/helperFunctions";
import {ROLE_ITEMS} from "../../../../utils/constants";
import UserDeleteModal from "../../../../modules/UserInfo/components/UserDeleteModal/UserDeleteModal";


const initialState = {
	first_name: undefined,
	last_name: undefined,
	company: undefined,
	position: undefined,
	login: undefined,
	password: undefined,
	note: undefined,
}
const initialContactState = {
	email: [''],
	phone_number: [''],
	linkedin: [''],
	facebook: [''],
}
const initialErrors = {
	login: '',
	password: '',
	first_name: '',
	last_name: '',
	phone_number: [],
	email: [],
	linkedin: [],
	facebook: [],
}

function Profile(props) {
	const {userById, highlights, videosList, personById, isFullWidth, rolesList} = props
	const [userInfo, setUserInfo] = useState(initialState)
	const [contactItems, setContactItems] = useState(initialContactState);
	const [errors, setErrors] = useState(initialErrors);
	const [haveChanges, setHaveChanges] = useState(new Map());
	const [activeLanguage, setActiveLanguage] = useState('ru')
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [userRole, setUserRole] = useState(undefined);
	const [toastInfo, setToastInfo] = useState({isShow: false, type: 'danger'});
	const dispatch = useDispatch();
	const userProfile = useSelector(state => state.profile.userProfile);
	const [currentUser, setCurrentUser] = useState(null);
	const [showModal, setShowModal] = useState(false)
	const {t, i18n} = useTranslation();
	const isCheckPermissionEditUserInfo = hasPermission(ROLE_ITEMS.SELF_PROFILE_USER_SETTINGS_EDIT);
	const isCheckPermissionDeletePerson = hasPermission(ROLE_ITEMS.SELF_PROFILE_DELETE);
	const [isVisibleDeleteAccountModal, setVisibleDeleteAccountModal] = useState(false);

	useLayoutEffect(() => {
		props.ClearPersonById()
		props.ClearVideoList()
	}, [])


	useEffect(() => {
		props.ChangeHeaderTitle(t('profile_title'))
		props.GetUserProfile()
		props.GetChannels()
		dispatch(GetRoles());
	}, []);

	function toggleUserModal(person) {
		if(person && ((userProfile?.attached_person?.id === person?.person_id)|| (userProfile?.id === person?.user_id))){
			return
		}else{
			setShowModal(!showModal)
			setCurrentUser(person)
		}
		if(showModal){
			updateUserInfo({user_id: userProfile?.id, person_id: userProfile?.attached_person?.id})
			let reqData = {}
			userProfile?.attached_person?.id && (reqData.person_id = userProfile?.attached_person?.id)

			props.GetHighlights(reqData)
			userProfile?.id && props.GetVideosService({
				person_id: userProfile?.attached_person?.id ? userProfile?.attached_person?.id : userProfile?.id
			})
		}
	}

	const updateUserInfo = async (data) => {
		let personObj = {}
		let userObj = {}
		if (data?.person_id) {
			const response = await props.GetPersonById(data?.person_id);
			personObj = response;
			setContactItems(isCheckContactInfo(contactItems, response))
		}
		if (data?.user_id) {
			userObj = await props.GetUserById(data?.user_id)
		}
		setUserInfo(isCheckUserInfo(userInfo, personObj,  userObj));
	}

	useEffect(() => {
		updateUserInfo({user_id: userProfile?.id, person_id: userProfile?.attached_person?.id})
		let reqData = {}
		userProfile?.attached_person?.id && (reqData.person_id = userProfile?.attached_person?.id)

		props.GetHighlights(reqData)
		userProfile?.id && props.GetVideosService({
			person_id: userProfile?.attached_person?.id ? userProfile?.attached_person?.id : userProfile?.id
		})
	}, [userProfile?.id]);

	useEffect(() => {

		setUserRole(userById?.roles?.[0])
	}, [userById?.id, personById?.id]);

	const tagColors = ['#4F40F7', '#2877D4', '#F7404B', '#CB43C6', '#CB7443']

	useEffect(() => {
		changeLanguage('en')
	}, [])

	const changeLanguage = (language) => {
		i18n.changeLanguage(language);
		setActiveLanguage(language)
	}

	function getInputValue(e) {
		const {name, value} = e.target

		setUserInfo({
			...userInfo,
			[name]: value
		})
		setErrors({
			...errors,
			[name]: false
		})

		const changes = new Map(haveChanges);
		if((personById?.[name] || userProfile?.[name] || '') !== value) {
			changes.set(name, value)
		} else {
			changes.delete(name)
		}
		setHaveChanges(changes)
	}

	function checkValidation(data) {
		setErrors(initialErrors)
		const result = validateData({
			data,
			initialErrors,
			t,
			id: personById?.associated_user?.id
		})
		setErrors(result.errors)
		return result.isValid
	}

	function clearHaveChanges() {
		const changes = new Map(haveChanges);
		changes.clear()
		setHaveChanges(changes)
	}

	function toggleRole(e, role) {
		e.stopPropagation()
		setUserRole(role)

		const changes = new Map(haveChanges);
		if(userById?.role?.[0].id !== role?.id) {
			changes.set('userRole', role?.id)
		} else {
			changes.delete('userRole')
		}
		setHaveChanges(changes);
	}

	function saveChanges() {
		// role_ids: [userRole?.id]
		let recData = {};
		let recDataPerson = {}
		if(userProfile?.attached_person.id) {
			userInfo.first_name && (recDataPerson.first_name = userInfo.first_name)
			userInfo.last_name && (recDataPerson.last_name = userInfo.last_name)
			userInfo.position && (recDataPerson.position = userInfo.position)
			userInfo.note && (recDataPerson.note = userInfo.note)
			userInfo.organization && (recDataPerson.organization = userInfo.organization)

			Object.entries(contactItems).forEach(([key, value]) => {
				let item = value.filter(i => !!i)
				item.length && (recDataPerson[key] = item)
			})

			if(!personById?.associated_with_user) {
				userInfo?.login && (recDataPerson.user = {
					...recDataPerson?.user,
					email: userInfo?.login
				})
				userInfo?.password && (recDataPerson.user = {
					...recDataPerson?.user,
					password: userInfo?.password
				})
			}

			checkValidation(recDataPerson) && props.UpdatePersons(userProfile?.attached_person.id, recDataPerson, userInfo)
			clearHaveChanges()
		}
		if(userInfo?.login || userInfo?.password) {
			userInfo?.first_name && (recData.first_name = userInfo.first_name)
			userInfo?.last_name && (recData.last_name = userInfo.last_name)
			userInfo?.login && (userInfo?.login !== userById?.login) && (recData.login = userInfo.login)
			userInfo?.password && (recData.password = userInfo.password)

			if(checkValidation(recData)) {
				props.UpdateUser(userProfile?.id, recData)
				props.GetUserById(userProfile?.id)
				clearHaveChanges()
			}
		}
	}

	const logOut = async () => {
		await dispatch(LogOut())
		history.push('/');
		ClearProfile()
	}
	async function deleteUser() {
		try {
			const userId = userInfo?.user_id;
			const personId = userInfo?.person_id;
			await DeleteUserService(userId);
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("delete_person_success_text")
			});
			if(personId){
				await updateUserInfo({person_id: personId, user_id: null});
			}else{
				logOut();
			}
		} catch (error) {
			console.error(error);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("delete_person_error_text")
			});
		}
	}
	async function deleteAccount() {
		try {
			const userId = userInfo?.user_id;
			const personId = userInfo?.person_id;
			if(personId){
				await DeletePersonService(personId);
			}
			if(userId){
				await DeleteUserService(userId);
			}
			setToastInfo({
				isShow: true,
				type: 'success',
				text: t("delete_person_success_text")
			});
			logOut();
		} catch (error) {
			console.error(error);
			setToastInfo({
				isShow: true,
				type: 'danger',
				text: t("delete_person_error_text")
			});
		}
	}

	const onConfirmDeleteProfileButton = () => {
		setVisibleDeleteAccountModal(false)
		deleteUser();
	}

	return (
		<UserPersonInfoContext.Provider value={{userInfo, updateUserInfo}}>
			<div className={`main-container profile-page  ${isFullWidth ? '' : 'full'}`}>
				<div className="profile-page__form">
					<div className="profile-page-header">{t('profile_page_title')}</div>
					<div className="profile-page__form__wrapper">
						<div className="profile-page__form__media">
							<ProfileMedia personById={personById}/>
							<ProfileTags user={userProfile} personById={personById} isProfile={true} userInfo={userInfo}/>
						</div>
						<form autoComplete={'off'} className="profile-page__form__content">
							<div className="row-item">
								<div className="input_group">
									<label htmlFor="">{t('input_name')}</label>
									<input type="text"
									       value={userInfo?.first_name}
									       name={'first_name'}
									       onChange={getInputValue}
									       placeholder={t('input_name')}
									       autoComplete={'off'}
									/>
									{!!errors?.first_name && (
										<div className="input_group__error">{errors?.first_name}</div>
									)}
								</div>
								<div className="input_group">
									<label htmlFor="">{t('input_surname')}</label>
									<input type="text"
									       value={userInfo?.last_name}
									       name={'last_name'}
									       onChange={getInputValue}
									       placeholder={t('input_surname')}
									       autoComplete={'off'}
									/>
									{!!errors.last_name && (
										<div className="input_group__error">{errors.last_name}</div>
									)}
								</div>
							</div>
							<div className="row-item">
								<div className="input_group">
									<label htmlFor="">{t('input_company')}</label>
									<input type="text"
									       value={userInfo?.organization}
									       name={'organization'}
									       disabled={!userProfile?.company?.id || !userProfile?.attached_person?.id}
									       onChange={getInputValue}
									       placeholder={t('input_company')}
									/>
								</div>
								<div className="input_group">
									<label htmlFor="">{t('input_position')}</label>
									<input type="text"
									       value={userInfo?.position}
									       name={'position'}
									       disabled={!userProfile?.attached_person?.id}
									       onChange={getInputValue}
									       placeholder={t('input_position')}
									/>
								</div>
							</div>

							<div className="input_group full">
								<label htmlFor="">{t('input_notes')}</label>
								<textarea value={userInfo?.note}
								          name={'note'}
								          disabled={!userProfile?.attached_person?.id}
								          onChange={getInputValue}
								          placeholder={t(config.productName)}
								/>
							</div>
						</form>
					</div>

					<ProfileContacts
						contactItems={contactItems}
						setContactItems={setContactItems}
						errors={errors}
						setErrors={setErrors}
						haveChanges={haveChanges}
						setHaveChanges={setHaveChanges}
						personById={personById}
						isProfile={true}
					/>

					<div className="profile-page__settings_tab">
						<Tabs
							defaultActiveKey="0"
							id="uncontrolled-tab-example">
							<Tab eventKey="0"
							     className={'settings_content'}
							     title={
								     <span className="tab-title">
                                 <span>{t('profile_info_title')}</span>
									     {!(videosList?.count || highlights?.length) ?
										     <InfoTooltip title={t('tooltip_info_title')}
										                  description={t('tooltip_info_description')}
										     /> : <InfoCheckIcon/>}
                    </span>
							     }>
								<ParticipationData videosList={videosList}
								                   user={userProfile}
								                   highlights={highlights}
								                   userRoles={props.userRoles}
								                   isProfile={true}
								                   userInfo={userInfo}
								                   onOpenModal={toggleUserModal}
								/>
							</Tab>
							<Tab eventKey="1"
							     className={'settings_content'}
							     title={
								     <span className="tab-title">
                       <span>{t('profile_settings_title')}</span>
									     {!userInfo?.login ? <InfoTooltip title={t('tooltip_settings_title')}
									                                      description={t('tooltip_settings_description')}/>
										     : <InfoCheckIcon/>}
                    </span>
							     }>
								<div className="user-info-settings">
									<p className="user-info-settings--description">{t('profile_settings_desc')}</p>
									<div className="input_group">
										<label htmlFor="">{t('input_login')}</label>
										<input type="text"
										       value={userInfo?.login}
										       name={'login'}
										       onChange={getInputValue}
										       placeholder={t('input_login')}
										       autoComplete={'off'}
										       disabled={!isCheckPermissionEditUserInfo}
										/>
										{!!errors.login && (
											<div className="input_group__error">{errors.login}</div>
										)}
									</div>
									<div className="input_group">
										<label htmlFor="">{t('input_password')}</label>
										<input
											type={`${passwordVisible ? "text" : "password"}`}
											value={userInfo?.password}
											name={'password'}
											onChange={getInputValue}
											placeholder={t('input_password')}
											autoComplete="new-password"
											autoFocus={false}
											disabled={!isCheckPermissionEditUserInfo}
										/>
										<EyeIcon className="input-icon"
										         onClick={() => setPasswordVisible(!passwordVisible)}/>
										{!!errors.password && (
											<div className="input_group__error">{errors.password}</div>
										)}
									</div>
								</div>

								<Accordion  className={'accordion-wrapper roles-accordion'}>
									<Accordion.Item eventKey="1" className={'roles-accordion__wrap'}>
										<Accordion.Header
											className={`roles-accordion__wrap--header `}>
											<ArrowDownIcon className={`arrow-icon`}/>
											{t('profile_roles')}
										</Accordion.Header>
										<Accordion.Body>
											{false ?
											<UserRoles rolesList={rolesList}
											           // toggleRole={toggleRole}
											           userRole={userRole}
											/>
											:
											<div className="roles-accordion__list">
													<div className="roles-accordion__list--item">
														{userRole?.name}
													</div>
											</div>
										}
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
								<ProfileLanguage/>
								{(userProfile?.id && isCheckPermissionDeletePerson) ?
									<div className="remove-user">
										<div className="remove-user__wrap">
											<span className="remove-user__wrap--title">{t('remove_user_settings')}</span>
											<div className="remove-user__wrap--description">
												<span>
													{t('remove_user_settings_description')}
												</span>
												<button onClick={() => setVisibleDeleteAccountModal(true)}>
													<DeletingIcon/>
													{t('button_delete_information')}
												</button>
											</div>
										</div>
									</div>
									: null}
							</Tab>
						</Tabs>
					</div>
					{hasPermission(ROLE_ITEMS.SELF_PROFILE_DELETE) &&
						<DeletePerson deleteAccount={deleteAccount}/>
					}
					<div className="buttons-wrapper">
						<button className={`close_btn`}
						>
							{t('button_cancel')}
						</button>
						<button className={`save_btn ${!haveChanges.size ? 'disabled' : ''}`}
						        disabled={!haveChanges.size}
						        onClick={saveChanges}
						>
							{t('profile_save_tag')}
						</button>
					</div>
				</div>
			</div>
			<Toast
				className='upload_video_answer'
				onClose={() => setToastInfo({isShow: false, type: toastInfo.type})}
				show={toastInfo.isShow}
				delay={3000}
				autohide
			>
				<div className="toasblock">
					{toastInfo.type === 'danger' ? <ErrorIcon/> : <SuccessIcon/>}
					<div className="toasblock__wrap">
						                <span
							                className="toasblock__wrap--title">{toastInfo.type === 'danger' ? t('toast_error_title') : t('toast_success_title')}</span>
						<span className="toasblock__wrap--text">{toastInfo?.text || ''}</span>
					</div>
				</div>
			</Toast>
			{showModal && <UserInfoModal show={showModal}
			                             closeModal={toggleUserModal}
			                             user={currentUser}
			/>}
			{isVisibleDeleteAccountModal &&
				<UserDeleteModal
					show={isVisibleDeleteAccountModal}
					onClose={() => setVisibleDeleteAccountModal(false)}
					onConfirm={onConfirmDeleteProfileButton}
					isDeleteUserSettings={true}
				/>}
		</UserPersonInfoContext.Provider>
	)
}

const mapStateToProps = (state) => {
	return getPropsFromState(state, [
		'userProfile', 'userById',
		'highlights', 'videosList', 'personById',
		'isFullWidth', 'rolesList', 'userRoles', 'personsList'
	])
};

const mapDispatchToProps = {
	UpdatePersons,
	UpdateUser,
	GetUserById,
	GetUserProfile,
	GetHighlights,
	GetVideos,
	GetPersonById,
	GetUsersPersons,
	ChangeHeaderTitle,
	ClearProfile,
	ClearPersonById,
	GetVideosService,
	ClearVideoList,
	GetChannels
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
